import { Suspense, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import CausesPage from "pages/donations/CausesPage";
import ContributionDonePage from "pages/promoters/ContributionDonePage";
import MainLayout from "layouts/MainLayout";
import CheckoutPage from "pages/promoters/CheckoutPage";
import CardPaymentInformationProvider from "contexts/cardPaymentInformationContext";
import DeleteAccountPage from "pages/users/DeleteAccountPage";
import { useLocation } from "react-router";
import { logPageView } from "lib/events";
import AppDownloadPage from "pages/users/AppDownloadPage";
import AboutPage from "pages/promoters/AboutPage";
import LoadingPage from "pages/donations/LoadingPage";
import ReturnToIntegrationPage from "pages/donations/ReturnToIntegrationPage";
import SurveyPage from "pages/promoters/SurveyPage";
import StripeProvider from "contexts/stripeContext";
import PixPaymentInformationProvider from "contexts/pixPaymentInformationContext";
import PaymentInformationProvider from "contexts/paymentInformationContext";
import RecurrencePage from "pages/promoters/CheckoutPage/RecurrencePage";
import ContributionCanceledPage from "pages/promoters/ContributionCanceledPage";
import CampaignPage from "pages/campaigns/CampaignPage";
import PixInstructionsPage from "pages/promoters/CheckoutPage/Components/PixInstructionsPage";
import ExpiredLinkPage from "pages/auth/ExpiredLinkPage";
import RedirectPage from "pages/campaigns/redirectPage";
import CustomLinkPage from "pages/users/CustomLinkPage";
import CustomLinkCreatedPage from "pages/users/CustomLinkCreatedPage";
import SignInCustomLinkPage from "pages/auth/SignInCustomLinkPage";
import SignInPage from "pages/auth/SignInPage";
import InsertEmailPage from "pages/auth/InsertEmailPage";
import SentMagicLinkEmailPage from "pages/auth/SentMagicLinkEmailPage";
import SignInByMagicLinkPage from "pages/auth/SignInByMagicLinkPage";
import TicketDonationDonePage from "pages/donations/TicketDonationDonePage";
import SelectTicketsPage from "pages/donations/SelectTicketsPage";
import ValidateAccountPage from "pages/auth/ValidateAccountPage";
import GiveTicketPage from "pages/donations/GiveTicketPage";
import SecondPage from "pages/onboarding/SecondPage";
import ThirdPage from "pages/onboarding/ThirdPage";
import FirstPage from "pages/onboarding/FirstPage";
import SignInCouponPage from "pages/coupons/auth/SignInCouponPage";
import GiveTicketCouponPage from "pages/coupons/GiveTicketCouponPage";
import CouponCollectedPage from "pages/coupons/CouponCollectedPage";
import ExpiredCouponPage from "pages/coupons/ExpiredCouponPage";
import InsertEmailCouponPage from "pages/coupons/auth/InsertEmailCouponPage";
import PostDonationPage from "pages/donations/PostDonationPage";
import FullWalletPage from "pages/tickets/FullWalletPage";
import NavigationBackHeader from "./Navigation/NavigationBackHeader";

function RoutesComponent(): JSX.Element {
  const location = useLocation();
  useEffect(() => {
    const urlName = location.pathname.replace(/\/\d+/, "");
    const { search, state } = location;

    logPageView(urlName, search, state);
  }, [location]);

  return (
    <Switch>
      <Route path="/" exact>
        <Suspense fallback={<div />}>
          <LoadingPage />
        </Suspense>
      </Route>

      <Route path="/auth/sign-in" exact>
        <Suspense fallback={<div />}>
          <MainLayout hideTickets fullSize>
            <SignInPage />
          </MainLayout>
        </Suspense>
      </Route>

      <Route path="/auth/insert-email" exact>
        <Suspense fallback={<div />}>
          <NavigationBackHeader />
          <InsertEmailPage />
        </Suspense>
      </Route>

      <Route path="/auth/sent-magic-link-email" exact>
        <Suspense fallback={<div />}>
          <NavigationBackHeader />
          <SentMagicLinkEmailPage />
        </Suspense>
      </Route>

      <Route path="/auth">
        <Suspense fallback={<div />}>
          <SignInByMagicLinkPage />
        </Suspense>
      </Route>

      <Route path="/redirect">
        <Suspense fallback={<div />}>
          <RedirectPage />
        </Suspense>
      </Route>

      <Route path="/coupons/sign-in" exact>
        <Suspense fallback={<div />}>
          <SignInCouponPage />
        </Suspense>
      </Route>

      <Route path="/coupons/insert-email" exact>
        <Suspense fallback={<div />}>
          <NavigationBackHeader />
          <InsertEmailCouponPage />
        </Suspense>
      </Route>

      <Route path="/coupons/give-ticket" exact>
        <Suspense fallback={<div />}>
          <GiveTicketCouponPage />
        </Suspense>
      </Route>

      <Route path="/coupons/coupon-collected" exact>
        <Suspense fallback={<div />}>
          <CouponCollectedPage />
        </Suspense>
      </Route>

      <Route path="/coupons/expired-coupon" exact>
        <Suspense fallback={<div />}>
          <ExpiredCouponPage />
        </Suspense>
      </Route>

      <Route path="/causes" exact>
        <Suspense fallback={<div />}>
          <MainLayout showNavigation>
            <CausesPage />
          </MainLayout>
        </Suspense>
      </Route>

      <Route path="/ticket-donation-done" exact>
        <Suspense fallback={<div />}>
          <TicketDonationDonePage />
        </Suspense>
      </Route>

      <Route path="/intro" exact>
        <Suspense fallback={<div />}>
          <FirstPage isOnboarding />
        </Suspense>
      </Route>

      <Route path="/full-wallet" exact>
        <Suspense fallback={<div />}>
          <FullWalletPage />
        </Suspense>
      </Route>

      <Route path="/intro/receive-tickets" exact>
        <Suspense fallback={<div />}>
          <GiveTicketPage />
        </Suspense>
      </Route>

      <Route path="/tickets" exact>
        <Suspense fallback={<div />}>
          <FirstPage />
        </Suspense>
      </Route>

      <Route path="/return-to-integration" exact>
        <Suspense fallback={<div />}>
          <ReturnToIntegrationPage />
        </Suspense>
      </Route>

      <Route path="/app-download" exact>
        <Suspense fallback={<div />}>
          <AppDownloadPage />
        </Suspense>
      </Route>

      <Route path="/sign-in-custom-link" exact>
        <Suspense fallback={<div />}>
          <NavigationBackHeader />
          <SignInCustomLinkPage />
        </Suspense>
      </Route>

      <Route path="/select-tickets" exact>
        <Suspense fallback={<div />}>
          <SelectTicketsPage />
        </Suspense>
      </Route>

      <Route path="/validate-account" exact>
        <Suspense fallback={<div />}>
          <ValidateAccountPage />
        </Suspense>
      </Route>

      <Route path="/contribution-done" exact>
        <Suspense fallback={<div />}>
          <ContributionDonePage />
        </Suspense>
      </Route>

      <Route path="/post-donation" exact>
        <Suspense fallback={<div />}>
          <PostDonationPage />
        </Suspense>
      </Route>

      <Route path="/promoters/checkout" exact>
        <Suspense fallback={<div />}>
          <MainLayout hideMenu showNavigation>
            <PaymentInformationProvider>
              <CardPaymentInformationProvider>
                <StripeProvider>
                  <PixPaymentInformationProvider>
                    <CheckoutPage />
                  </PixPaymentInformationProvider>
                </StripeProvider>
              </CardPaymentInformationProvider>
            </PaymentInformationProvider>
          </MainLayout>
        </Suspense>
      </Route>

      <Route path="/promoters/checkout/pix-instructions" exact>
        <Suspense fallback={<div />}>
          <MainLayout hideMenu>
            <PaymentInformationProvider>
              <CardPaymentInformationProvider>
                <StripeProvider>
                  <PixPaymentInformationProvider>
                    <PixInstructionsPage />
                  </PixPaymentInformationProvider>
                </StripeProvider>
              </CardPaymentInformationProvider>
            </PaymentInformationProvider>
          </MainLayout>
        </Suspense>
      </Route>

      <Route path="/promoters/recurrence" exact>
        <Suspense fallback={<div />}>
          <MainLayout hideMenu>
            <PaymentInformationProvider>
              <CardPaymentInformationProvider>
                <StripeProvider>
                  <RecurrencePage />
                </StripeProvider>
              </CardPaymentInformationProvider>
            </PaymentInformationProvider>
          </MainLayout>
        </Suspense>
      </Route>

      <Route path="/delete_account" exact>
        <Suspense fallback={<div />}>
          <DeleteAccountPage />
        </Suspense>
      </Route>

      <Route path="/expired-link" exact>
        <Suspense fallback={<div />}>
          <ExpiredLinkPage />
        </Suspense>
      </Route>

      <Route path="/earn" exact>
        <Suspense fallback={<div />}>
          <MainLayout hideHeader fullSize showNavigation>
            <AboutPage />
          </MainLayout>
        </Suspense>
      </Route>

      <Route path="/monthly-contribution-canceled" exact>
        <Suspense fallback={<div />}>
          <ContributionCanceledPage />
        </Suspense>
      </Route>

      <Route path="/survey" exact>
        <Suspense fallback={<div />}>
          <SurveyPage />
        </Suspense>
      </Route>

      <Route path="/campaign" exact>
        <Suspense fallback={<div />}>
          <CampaignPage />
        </Suspense>
      </Route>

      <Route path="/dia-de-doar" exact>
        <Suspense fallback={<div />}>
          <MainLayout hideHeader fullSize>
            <AboutPage />
          </MainLayout>
        </Suspense>
      </Route>

      <Route path="/custom-link" exact>
        <Suspense fallback={<div />}>
          <MainLayout hideHeader>
            <CustomLinkPage />
          </MainLayout>
        </Suspense>
      </Route>

      <Route path="/custom-link-created" exact>
        <Suspense fallback={<div />}>
          <CustomLinkCreatedPage />
        </Suspense>
      </Route>

      <Route path="/intro/step-2" exact>
        <Suspense fallback={<div />}>
          <SecondPage />
        </Suspense>
      </Route>

      <Route path="/intro/step-3" exact>
        <Suspense fallback={<div />}>
          <ThirdPage />
        </Suspense>
      </Route>
    </Switch>
  );
}

export default RoutesComponent;
