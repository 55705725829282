import { useNonProfits } from "@ribon.io/shared/hooks";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { NonProfit, Tag } from "@ribon.io/shared/types";
import { useCurrentUser } from "contexts/currentUserContext";

export interface INonProfitsContext {
  nonProfits: NonProfit[] | undefined;
  shuffledNonProfits: NonProfit[];
  shuffledTags: Tag[];
  refetch: () => void;
  isLoading: boolean;
}

export const NonProfitsContext = createContext<INonProfitsContext>(
  {} as INonProfitsContext,
);
NonProfitsContext.displayName = "NonProfitsContext";

function NonProfitsProvider({ children }: any) {
  const { nonProfits, refetch, isLoading } = useNonProfits();
  const { signedIn } = useCurrentUser();
  const [shuffledTags, setShuffledTags] = useState<Tag[]>([]);
  const [nonProfitsBusiness, setNonProfitsBusiness] = useState<NonProfit[]>([]);
  const [nonProfitsClub, setNonProfitsClub] = useState<NonProfit[]>([]);
  const [nonProfitsFree, setNonProfitsFree] = useState<NonProfit[]>([]);
  const [shuffledNonProfits, setShuffledNonProfits] = useState<NonProfit[]>([]);

  useEffect(() => {
    setNonProfitsBusiness(
      (nonProfits || [])
        ?.filter(
          (nonProfit) =>
            nonProfit.cause.withFundBalance && nonProfit.kind === "business",
        )
        .sort(() => 0.5 - Math.random()) || [],
    );
    setNonProfitsClub(
      (nonProfits || [])
        ?.filter(
          (nonProfit) =>
            nonProfit.cause.withFundBalance && nonProfit.kind === "club",
        )
        .sort(() => 0.5 - Math.random()) || [],
    );
    setNonProfitsFree(
      (nonProfits || [])
        ?.filter(
          (nonProfit) =>
            nonProfit.cause.withFundBalance && nonProfit.kind === "free",
        )
        .sort(() => 0.5 - Math.random()) || [],
    );
  }, [nonProfits]);

  useEffect(() => {
    setShuffledNonProfits([
      ...nonProfitsBusiness,
      ...nonProfitsClub,
      ...nonProfitsFree,
    ]);
  }, [nonProfitsBusiness, nonProfitsClub, nonProfitsFree]);

  useEffect(() => {
    refetch();
  }, [signedIn]);

  useEffect(() => {
    const allTags = shuffledNonProfits
      .map((nonProfit) => nonProfit.tags)
      .flat();

    const uniqueTagsMap = new Map<number, Tag>();
    allTags.forEach((tag) => {
      if (tag) uniqueTagsMap.set(tag.id, tag);
    });
    setShuffledTags(Array.from(uniqueTagsMap.values()));
  }, [shuffledNonProfits]);

  const nonProfitsObject: INonProfitsContext = useMemo(
    () => ({
      nonProfits,
      shuffledNonProfits,
      shuffledTags,
      refetch,
      isLoading,
    }),
    [nonProfits, refetch, isLoading, shuffledNonProfits, shuffledTags],
  );

  return (
    <NonProfitsContext.Provider value={nonProfitsObject}>
      {children}
    </NonProfitsContext.Provider>
  );
}

export default NonProfitsProvider;

export const useNonProfitsContext = () => {
  const context = useContext(NonProfitsContext);

  if (!context) {
    throw new Error("useNonProfits must be used within NonProfitsProvider");
  }

  return context;
};
