import { PLATFORM } from "utils/constants";
import { logError } from "services/crashReport";
import { useUserTickets } from "@ribon.io/shared/hooks";
import { NonProfit } from "@ribon.io/shared/types";
import useNavigation from "hooks/useNavigation";
import { getUTMFromLocationSearch } from "lib/getUTMFromLocationSearch";
import useDonateTickets from "hooks/apiHooks/useDonateTickets";
import { useAuthentication } from "contexts/authenticationContext";

type HandleDonateProps = {
  nonProfit: NonProfit;
  ticketsQuantity: number;
  onSuccess?: () => void;
  onError?: (error: any) => void;
};

function useDonationFlow() {
  const { isAuthenticated } = useAuthentication();
  const { history } = useNavigation();
  const utmParams = getUTMFromLocationSearch(history.location.search);

  async function handleDonate({
    nonProfit,
    ticketsQuantity,
    onError,
    onSuccess,
  }: HandleDonateProps) {
    const { donate: userDonate } = useUserTickets();
    const { donate: apiDonate } = useDonateTickets();

    try {
      if (isAuthenticated()) {
        const result = await userDonate(
          nonProfit.id,
          ticketsQuantity,
          PLATFORM,
          utmParams.utmSource,
          utmParams.utmMedium,
          utmParams.utmCampaign,
        );
        if (result?.status === 200 && onSuccess) onSuccess();
      } else {
        const result = await apiDonate(
          nonProfit.id,
          ticketsQuantity,
          PLATFORM,
          utmParams.utmSource,
          utmParams.utmMedium,
          utmParams.utmCampaign,
        );
        if (result?.status === 200 && onSuccess) onSuccess();
      }
    } catch (e: any) {
      logError(e);
      if (onError) onError(e);
    }
  }

  return { handleDonate };
}

export default useDonationFlow;
